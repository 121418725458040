import React, {useEffect, useState, useRef} from 'react'
import deleteLeafletAttribution from '../../../Utilities/deleteLeafletAttribution';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import L from 'leaflet'
import 'leaflet-search/dist/leaflet-search.min.css';
import 'leaflet-search';
import { useSelector } from 'react-redux'
import api from '../../../../store/api';
import MainBarChart from '../../../Charts/BarChart/BarChart';
import DoughnutChart from '../../../Charts/DoughnutChart/DoughnutChart';
import { BarChart } from 'recharts';
import SimpleLineChart from '../../../Charts/LineChart/LineChart';

export default function ActivationDashboard() {

    const [activationCount, setActivationCount] = useState({})
    const [pageLoading, setPageLoading] = useState(false)
    const [activationLocationInfo, setLocationActivationInfo] = useState([])
    const [activationPerCommissioningAgency, setActivationPerCommissioningAgency] = useState([])
    const [activationPerBrand, setActivationPerBrand] = useState([])
    const [activationPerProject, setActivationPerProject] = useState([])
    const [activationPerWeek, setActivationPerWeek] = useState([])
    const [activationPerMonth, setActivationPerMonth] = useState([])

    const mapRef = useRef(null)
    const marker = useRef(null)
    const initCoordinates = [-1.2460227009959508, 36.68168221244762];
    const [markerPosition, setMarkerPosition] = useState(null)

    const onMapReady = (map) => {
        map = map.current
        if (map){
            map.invalidateSize()
        }
    };

    const handleSetMarkerPosition = (coordinates) => {
        const { lat, long } = coordinates
        if ((lat && long) && mapRef.current) {
            setMarkerPosition({lat: parseFloat(lat), lng: parseFloat(long)})
            const marker = L.marker([51.505, -0.09], { icon: customIcon }).addTo(mapRef.current);
            marker.setLatLng([lat, long]);
            const newLatLng = marker._latlng;
            mapRef.current.flyTo(newLatLng, 8);
        }
    }

    const customIcon = L.icon({
        iconUrl: '/static/assets/media/illustrations/beysix-1/location-pin.png',
        iconSize: [38, 38],
        iconAnchor: [19, 38],
        popupAnchor: [0, -38]
    });

    const getActivationDetails = () => {
        api.get(`/api/dashboards/get-activation-count/${organization.id}/`,
          {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
          .then((response) => {
              if (response.status === 200) {
                setActivationCount(response.data.count)
                setLocationActivationInfo(response.data.activation_coordinates)
                setActivationPerCommissioningAgency(response.data.activation_per_commissioning_agency)
                setActivationPerBrand(response.data.activations_per_brand)
                setActivationPerProject(response.data.activations_per_project)
                setActivationPerWeek(response.data.activations_per_week )
                setActivationPerMonth(response.data.activations_per_month )
                for (let i = 0; i < response.data.activation_coordinates.length; i++) 
                    handleSetMarkerPosition(response.data.activation_coordinates[i].coords)
                setPageLoading(false)
              }
          })
          .catch((error) => {
              setPageLoading(false)
              throw new Error('Error getting data.')
          })
      }
    
      const {organization, token} = useSelector((state) => {
        return {
          'organization': state.loginSlice.organization, 
          'token': state.loginSlice.token
        }
      }); //
    
      useEffect(() => {
        getActivationDetails()
      }, [])

  return (
    <div className="row g-6 g-xl-9">
        <div className="col-lg-6 col-xxl-3">
            {/*begin::Card*/}
            <div className="card h-100">
                {/*begin::Card body*/}
                <div className="card-body p-9">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <div className="fs-2hx fw-bolder">{activationCount.total_activations__count}</div>
                      <span className="svg-icon svg-icon-primary svg-icon-3hx" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M8 22C7.4 22 7 21.6 7 21V9C7 8.4 7.4 8 8 8C8.6 8 9 8.4 9 9V21C9 21.6 8.6 22 8 22Z" fill="black"></path><path opacity="0.3" d="M4 15C3.4 15 3 14.6 3 14V6C3 5.4 3.4 5 4 5C4.6 5 5 5.4 5 6V14C5 14.6 4.6 15 4 15ZM13 19V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19ZM17 16V5C17 4.4 16.6 4 16 4C15.4 4 15 4.4 15 5V16C15 16.6 15.4 17 16 17C16.6 17 17 16.6 17 16ZM21 18V10C21 9.4 20.6 9 20 9C19.4 9 19 9.4 19 10V18C19 18.6 19.4 19 20 19C20.6 19 21 18.6 21 18Z" fill="black"></path></svg>
                      </span>
                    </div>
                    <div className="fs-5 fwt-bold text-gray-400 mb-7">Total {activationCount.total_activations__count === 1 ? 'Activation' : 'Activations'}</div>
                </div>
                {/*end::Card body*/}
            </div>
            {/*end::Card*/}
        </div>
        <div className="col-lg-6 col-xxl-2">
            {/*begin::Budget*/}
            <div className="card h-100">
                {/*begin::Card body*/}
                <div className="card-body p-9">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="fs-2hx fw-bolder">{activationCount.pending_activations_count}</div>
                      <span className="svg-icon svg-icon-3hx svg-icon-muted" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M8 22C7.4 22 7 21.6 7 21V9C7 8.4 7.4 8 8 8C8.6 8 9 8.4 9 9V21C9 21.6 8.6 22 8 22Z" fill="black"></path><path opacity="0.3" d="M4 15C3.4 15 3 14.6 3 14V6C3 5.4 3.4 5 4 5C4.6 5 5 5.4 5 6V14C5 14.6 4.6 15 4 15ZM13 19V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19ZM17 16V5C17 4.4 16.6 4 16 4C15.4 4 15 4.4 15 5V16C15 16.6 15.4 17 16 17C16.6 17 17 16.6 17 16ZM21 18V10C21 9.4 20.6 9 20 9C19.4 9 19 9.4 19 10V18C19 18.6 19.4 19 20 19C20.6 19 21 18.6 21 18Z" fill="black"></path></svg>
                      </span>
                    </div>
                    <div className="fs-5 fwt-bold text-gray-400 mb-7">Pending {activationCount.pending_activations_count === 1 ? 'Activation' : 'Activations'}</div>
                </div>
            {/*end::Budget*/}
        </div>
        </div>
        <div className="col-lg-6 col-xxl-2">
            {/*begin::Budget*/}
            <div className="card h-100">
                {/*begin::Card body*/}
                <div className="card-body p-9">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="fs-2hx fw-bolder">{activationCount.active_activations_count}</div>
                      <span className="svg-icon svg-icon-3hx svg-icon-info" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M8 22C7.4 22 7 21.6 7 21V9C7 8.4 7.4 8 8 8C8.6 8 9 8.4 9 9V21C9 21.6 8.6 22 8 22Z" fill="black"></path><path opacity="0.3" d="M4 15C3.4 15 3 14.6 3 14V6C3 5.4 3.4 5 4 5C4.6 5 5 5.4 5 6V14C5 14.6 4.6 15 4 15ZM13 19V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19ZM17 16V5C17 4.4 16.6 4 16 4C15.4 4 15 4.4 15 5V16C15 16.6 15.4 17 16 17C16.6 17 17 16.6 17 16ZM21 18V10C21 9.4 20.6 9 20 9C19.4 9 19 9.4 19 10V18C19 18.6 19.4 19 20 19C20.6 19 21 18.6 21 18Z" fill="black"></path></svg>
                      </span>
                    </div>
                    <div className="fs-5 fwt-bold text-gray-400 mb-7">Active {activationCount.active_activations_count === 1 ? 'Activation' : 'Activations'}</div>
                </div>
            {/*end::Budget*/}
        </div>
        </div>
        <div className="col-lg-6 col-xxl-2">
            {/*begin::Clients*/}
            <div className="card h-100">
                <div className="card-body p-9">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="fs-2hx fw-bolder">{activationCount.completed_activations_count}</div>
                      <span className="svg-icon svg-icon-3hx svg-icon-success" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M8 22C7.4 22 7 21.6 7 21V9C7 8.4 7.4 8 8 8C8.6 8 9 8.4 9 9V21C9 21.6 8.6 22 8 22Z" fill="black"></path><path opacity="0.3" d="M4 15C3.4 15 3 14.6 3 14V6C3 5.4 3.4 5 4 5C4.6 5 5 5.4 5 6V14C5 14.6 4.6 15 4 15ZM13 19V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19ZM17 16V5C17 4.4 16.6 4 16 4C15.4 4 15 4.4 15 5V16C15 16.6 15.4 17 16 17C16.6 17 17 16.6 17 16ZM21 18V10C21 9.4 20.6 9 20 9C19.4 9 19 9.4 19 10V18C19 18.6 19.4 19 20 19C20.6 19 21 18.6 21 18Z" fill="black"></path></svg>
                      </span>
                    </div>
                    <div className="fs-5 fwt-bold text-gray-400 mb-7">Completed {activationCount.completed_activations_count === 1 ? 'Activation' : 'Activations'}</div>
                </div>
            </div>
            {/*end::Clients*/}
        </div>
        <div className="col-lg-6 col-xxl-3">
            {/*begin::Clients*/}
            <div className="card h-100">
                <div className="card-body p-9">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="fs-2hx fw-bolder">{activationCount.cancelled_activations_count}</div>
                        <span className="svg-icon svg-icon-3hx svg-icon-danger" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M8 22C7.4 22 7 21.6 7 21V9C7 8.4 7.4 8 8 8C8.6 8 9 8.4 9 9V21C9 21.6 8.6 22 8 22Z" fill="black"></path><path opacity="0.3" d="M4 15C3.4 15 3 14.6 3 14V6C3 5.4 3.4 5 4 5C4.6 5 5 5.4 5 6V14C5 14.6 4.6 15 4 15ZM13 19V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19ZM17 16V5C17 4.4 16.6 4 16 4C15.4 4 15 4.4 15 5V16C15 16.6 15.4 17 16 17C16.6 17 17 16.6 17 16ZM21 18V10C21 9.4 20.6 9 20 9C19.4 9 19 9.4 19 10V18C19 18.6 19.4 19 20 19C20.6 19 21 18.6 21 18Z" fill="black"></path></svg>
                      </span>
                    </div>
                    <div className="fs-5 fwt-bold text-gray-400 mb-7">Cancelled {activationCount.cancelled_activations_count === 1 ? 'Activation' : 'Activations'}</div>
                </div>
            </div>
            {/*end::Clients*/}
        </div>
        <div className='col-md-6 mt-3'>
        <div className="card card-flush">
                <div className="card-body d-flex flex-column" style={{padding: "0px"}}>
                    <MapContainer
                            ref={mapRef}
                            center={initCoordinates}
                            zoom={14}
                            style={{ height: "400px", width: "100%" }}
                        >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {markerPosition && (
                            <Marker position={markerPosition} icon={customIcon} ref={marker}>
                                {activationLocationInfo.map((activation, index) => 
                                <Popup key={index} >
                                    {activation.activation_name.concat(' Activation')}<br /> {parseFloat(activation.coords.lat).toFixed(4)}, {parseFloat(activation.coords.long).toFixed(4)}
                                </Popup>)}
                            </Marker>
                        )}
                    </MapContainer>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-xxl-6 mt-3">
                <div className="card card-xl-stretch ">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-2">
                        <h5 className="card-title align-items-start flex-column mb-2">
                        <span className="fwt-bolder text-dark">Activation Per Commissioning Agency</span>
                        </h5>
                    <MainBarChart _data = {activationPerCommissioningAgency} />
                </div>
                </div>
            </div>
            <div className="col-lg-4 col-xxl-4 mt-3">
                <div className="card card-xl-stretch ">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-2">
                        <h5 className="card-title align-items-start flex-column mb-2">
                        <span className="fwt-bolder text-dark">Activations per brand</span>
                        </h5>
                    <DoughnutChart _data = {activationPerBrand} _name = "Brand_name" />
                </div>
                </div>          
            </div> 
            <div className="col-lg-4 col-xxl-6 mt-3">
                <div className="card card-xl-stretch ">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-2">
                        <h5 className="card-title align-items-start flex-column mb-2">
                        <span className="fwt-bolder text-dark">Activations per project</span>
                        </h5>
                    <MainBarChart _data = {activationPerProject} _name = "project_name" />
                </div>
                </div>          
            </div> 
            <div className="col-lg-4 col-xxl-6 mt-3">
                <div className="card card-xl-stretch ">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-2">
                        <h5 className="card-title align-items-start flex-column mb-2">
                        <span className="fwt-bolder text-dark">Number of Activations Per Week</span>
                        </h5>
                    <SimpleLineChart _data = {activationPerWeek}  />
                </div>
                </div>          
            </div> 
            <div className="col-lg-4 col-xxl-6 mt-3">
                <div className="card card-xl-stretch ">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-2">
                        <h5 className="card-title align-items-start flex-column mb-2">
                        <span className="fwt-bolder text-dark">Number of Activations Per Month</span>
                        </h5>
                    <SimpleLineChart _data = {activationPerMonth}  />
                </div>
                </div>          
            </div> 
    </div>
  )
}
