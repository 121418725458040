import React, {useState} from 'react'
import ToolsAndAccessoriesMultipleSearchComponent from '../../Utilities/ToolsAndAccessoriesMultipleSearchComponent'
import { useSelector } from 'react-redux'
import api from '../../../store/api'
import { useParams } from 'react-router'

export default function ConfigureTaccs({handleUpdateToolsAndAccessories}) {

    const [activationData, setActivationData] = useState({
        activationTaccs: [],
        activationAccessories: [],
        activationTools: []
    })
    
    const {id} = useParams()

    const token = useSelector((state) => state.loginSlice.token);

    const [successfullSubmission, setSuccessfullSubmission] = useState(false)

    const [loading, setLoading] = useState(false)

    const handleSubmit = (evt) => {
        evt.preventDefault()
        // setLoading(true)
        if (activationData.activationTaccs.length > 0) {
            const data = {
                'activationTools': activationData.activationTools, 
                'activationAccessories': activationData.activationAccessories
            }
            api.post(`/api/activations/configure-tools-and-accessories/${id}/`, data,
              {headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Token ${token}`,
                  },
              })
              .then((response) => {
                  if (response.status === 201) {
                        const data = response.data.tools_and_accessories_data
                        setLoading(false)
                        if (data !== null || data !== undefined)
                            handleUpdateToolsAndAccessories(data, response.data.message)
                      } 
                })
              .catch((error) => {
                setLoading(false)
            })
        }
    }

    const removeTool = (id) => {
        const updatedActivationTools = activationData.activationTools.filter(tool => tool.id !== id);
        const updatedActivationTaccs = activationData.activationTaccs.filter(tacc => tacc.id !== id);

        setActivationData({
            ...activationData,
            activationTools: updatedActivationTools,
            activationTaccs: updatedActivationTaccs
        });
    }

    const removeAccessory = (id) => {
        const updatedActivationAccessories = activationData.activationAccessories.filter(accessory => accessory.id !== id);
        const updatedActivationTaccs = activationData.activationTaccs.filter(tacc => tacc.id !== id);

        setActivationData({
            ...activationData,
            activationAccessories: updatedActivationAccessories,
            activationTaccs: updatedActivationTaccs
        });
    }

    return (
        <div className="w-100">
        <div className="d-flex flex-column mt-7 fv-row col-md-12">
            <div>
                <ToolsAndAccessoriesMultipleSearchComponent returnResults = {(result) => {
                    if (result) {
                        let res = null
                        if (result.tool_name) {
                            if (!activationData.activationTools.some(tool => tool.id === result.id)) {
                                res = {'name': result.tool_name, 'id': result.id}
                                setActivationData({...activationData, activationTools: [...activationData.activationTools, res], activationTaccs: [...activationData.activationTaccs, {'id': res.id, 'tool': true}]})
                            }
                        }
                        else {
                            if (!activationData.activationAccessories.some(accessory => accessory.id === result.id)){
                                res = {'name': result.Accessory_name, 'id': result.id}
                                setActivationData({...activationData, activationAccessories: [...activationData.activationAccessories, res], activationTaccs: [...activationData.activationTaccs, {'id': res.id, 'tool': false}]})
                            }
                        }
                    }
                }} left={"75%"} successfullSubmission={successfullSubmission} inputName={'Add Activation Tools or Accessories'} selectedToolaccs={activationData.activationTaccs.map(tacc => tacc.id)} /> 
                <div className='row'>
                    {activationData.activationAccessories.length > 0 &&
                    <div className={activationData.activationTools.length > 0 ? 'col-md-6' : 'col-md-12'}>
                        <div style={{maxHeight: '400px', overflow: 'auto', marginTop: '15px', border: '1px solid #fcc282', padding: '10px', borderRadius: '10px'}}>
                            <p>Accessories</p>
                            <ul>
                                {activationData.activationAccessories.map((accessory, index) => (
                                    <li key={index} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '15px', marginRight: '15px', marginTop: '10px'}} className='badge badge-light-primary'>
                                        <span>{accessory.name}</span>
                                        <span onClick={() => {removeAccessory(accessory.id)}} className='bi bi-x fs-3' style={{cursor: 'pointer'}}></span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>}
                    {activationData.activationTools.length > 0 &&
                    <div className={activationData.activationAccessories.length > 0 ? 'col-md-6' : 'col-md-12'}>
                        <div style={{maxHeight: '400px', overflow: 'auto', marginTop: '15px', border: '1px solid #fcc282', padding: '10px', borderRadius: '10px'}}>
                            <p>Tools</p>
                            <ul>
                                {activationData.activationTools.map((tool, index) => (
                                    <li key={index} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '15px', marginRight: '15px', marginTop: '10px'}} className='badge badge-light-primary'>
                                        <span>{tool.name}</span>
                                        <span onClick={() => {removeTool(tool.id)}} className='bi bi-x fs-3' style={{cursor: 'pointer'}}></span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'right'}} className='mt-5'>    
            <button onClick={handleSubmit} disabled={loading ? true : false} id="kt_new_password_submit" className="btn btn-sm btn-beysix fwt-bolder">
                {!loading && <span className="indicator-label">Submit</span>}
                {loading && <span className="indicator-progress">Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>}
            </button>
        </div>
    </div>
    )
}
