import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import parseDateTimestamp from '../../Utilities/parseDateTimeStamp.'
import SkeletonLoader from '../SkeletonLoader/SkeletonLoader'
import { motion } from 'framer-motion';
import NoResults from '../../Utilities/NoResults';

export default function AgencyTable({agencyData, resultsFound, loading, hideClient = false}) {
  
  const [agencies, setAgencies] = useState([])
  const [res, setRes] = useState({
    message: "",
    severity: "success",
    show: false
  })

  useEffect(() => {
    setAgencies(agencyData)
  }, [agencyData])

  return (
    <>
      {(!loading && resultsFound) ? 
        <div className="table-responsive">
          <motion.table
                    initial={{ y: 100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.3 }}
                    className="table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 p-5"
            >
            {/*begin::Table head*/}
            <thead>
              <tr className="fw-bolder text-muted-2 bg-tr-light">
                <th className="ps-4 min-w-200px rounded-start">Agency Name</th>
                {hideClient && <th className="min-w-125px">No. of Clients</th>}
                <th className="min-w-125px">No. of Brands</th>
                <th className="min-w-125px">No. of Projects</th>
                <th className="min-w-125px">No. of Assets</th>
                <th className="min-w-125px">No. of Members</th>
                <th className="min-w-125px">Manager</th>
                {/* <th className="min-w-125px">Created Date</th>
                <th className="min-w-200px">Created By</th> */}
              </tr>
            </thead>
            {/*end::Table head*/}
            {/*begin::Table body*/}
            <tbody>
              {agencies && agencies.map((agency) => {
              return (<tr>
                    <td>
                      <div className="d-flex align-items-center">
                      <div className="symbol symbol-60px me-3" style={{width: '60px', height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {agency.profile_photo_path && agency.profile_photo_path !== '' ? 
                        <img src={agency.profile_photo_path} className="h-100 align-self-end" alt={agency.profile_photo_name} style={{width: '100%', height: '100%', borderRadius: '5px'}} />
                           :
                        <span className="svg-icon svg-icon-muted svg-icon-3x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M16.925 3.90078V8.00077L12.025 10.8008V5.10078L15.525 3.10078C16.125 2.80078 16.925 3.20078 16.925 3.90078ZM2.525 13.5008L6.025 15.5008L10.925 12.7008L6.025 9.90078L2.525 11.9008C1.825 12.3008 1.825 13.2008 2.525 13.5008ZM18.025 19.7008V15.6008L13.125 12.8008V18.5008L16.625 20.5008C17.225 20.8008 18.025 20.4008 18.025 19.7008Z" fill="black"/>
                        <path opacity="0.3" d="M8.52499 3.10078L12.025 5.10078V10.8008L7.125 8.00077V3.90078C7.125 3.20078 7.92499 2.80078 8.52499 3.10078ZM7.42499 20.5008L10.925 18.5008V12.8008L6.02499 15.6008V19.7008C6.02499 20.4008 6.82499 20.8008 7.42499 20.5008ZM21.525 11.9008L18.025 9.90078L13.125 12.7008L18.025 15.5008L21.525 13.5008C22.225 13.2008 22.225 12.3008 21.525 11.9008Z" fill="black"/>
                        </svg></span>
                        }
                      </div>
                      <div className="d-flex justify-content-start flex-column">
                          <Link to={`/agency-management/agencies/detailed-agency/${agency.id}`} className="text-dark fw-bolder text-hover-primary fs-6" href="/asset-management/detailed-asset/1">{agency.name}</Link>
                      </div>
                    </div>
                  </td>
                  {hideClient &&
                  <td>
                    <p className="text-dark fw-bolder d-block mb-1 fs-6">
                      {agency.client_count} {agency.client_count === 1 ? 'Client' : 'Clients'}
                    </p>
                  </td>}
                  <td>
                    <p className="text-dark fw-bolder d-block mb-1 fs-6">
                      {agency.brand_count} {agency.brand_count === 1 ? 'Brand' : 'Brands'}
                    </p>
                  </td>
                  <td>
                    <p  className="text-dark fw-bolder d-block mb-1 fs-6">
                      {agency.project_count} {agency.project_count === 1 ? 'Project' : 'Projects'}  
                    </p>
                  </td>
                  <td>
                    <p  className="text-dark fw-bolder d-block mb-1 fs-6">
                      {agency.asset_count} {agency.asset_count === 1 ? 'Asset' : 'Assets'}
                    </p>
                  </td>
                  <td>
                    <p  className="text-dark fw-bolder d-block mb-1 fs-6">
                      {agency.member_count} {agency.member_count === 1 ? 'Member' : 'Members'}
                    </p>
                  </td>
                  <td>
                    <Link to={`/user-management/users/user-profile/${agency.manager}`} className="text-dark fw-bolder d-block mb-1 fs-6">{agency.manager_name}</Link>
                  </td>
                  {/* <td>
                    <a className="text-dark fw-bolder d-block mb-1 fs-6">{parseDateTimestamp(agency.created_at)}</a>
                  </td>
                  <td>
                    <Link to={`/user-management/users/user-profile/${agency.created_by}`} className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{agency.created_by_name}</Link>
                  </td> */}
                </tr>
              )})}
            </tbody>
            {/*end::Table body*/}
          </motion.table>
        </div> : 
        (!resultsFound  && !loading) ? <NoResults /> :<SkeletonLoader />
        }
    </>
  )
}
