import React, {useState} from 'react'
import UserMultipleSearchComponent from '../../Utilities/UserMultipleSearchComponent'
import { useSelector } from 'react-redux'
import api from '../../../store/api'
import { useParams } from 'react-router'

export default function ConfigureProjectManagers({handleUpdateProjectManagers}) {

    const [assignedManagers, setAssignedManagers] = useState([])
    const [successfullSubmission, setSuccessfullSubmission] = useState(false)
    
    const [loading, setLoading] = useState(false)
    
    const handleRemoveManager = (indexToRemove, crewId) => {
        assignedManagers.splice(indexToRemove, 1)
        setAssignedManagers(assignedManagers)
    }

    const {id} = useParams()

    const token = useSelector((state) => state.loginSlice.token);

    const handleSubmit = (evt) => {
        evt.preventDefault()
        // setLoading(true)
        if (assignedManagers.length > 0) {
            const data = {'projectManagers': assignedManagers}
            api.post(`/api/activations/configure-project-managers/${id}/`, data,
              {headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Token ${token}`,
                  },
              })
              .then((response) => {
                  if (response.status === 201) {
                        const data = response.data.project_managers_data
                        setLoading(false)
                        if (data !== null || data !== undefined)
                            handleUpdateProjectManagers(data, response.data.message)
                      } 
                })
              .catch((error) => {
                setLoading(false)
            })
        }
    }

  return (
    <div className="w-100">
        {/*begin::Heading*/}
        <div className="pb-2 pb-lg-2">
            <div className="text-muted ftw-bold fs-6">Configure Project Managers assigned to this activation</div>
            {/*end::Notice*/}
        </div>
        {/*end::Heading*/}
        
        <UserMultipleSearchComponent  returnResults = {
            (result) => {
                const index = assignedManagers.findIndex(crew => crew.id === result.id);  
                if (index === -1) {
                    setAssignedManagers(prevSelectedCrew => [...prevSelectedCrew, result]);
                } else {
                    setAssignedManagers(prevSelectedCrew => prevSelectedCrew.filter(crew => crew.id !== result.id));
                    setTeamLeaders(prevTeamLeaders => prevTeamLeaders.filter(id => id !== result.id));
                }
            }
        } successfullSubmission={successfullSubmission} userType={'staff'} inputName={''} selectedUsers={assignedManagers.map(crew => crew.id)} />
        <div style={{maxHeight: '350px', overflow: 'auto', marginTop: '50px'}}>
            {assignedManagers.map((crew, index) => <div className="alert alert-beysix mb-2">
                <div className="crew-selection d-flex justify-content-between" key={index}>
                    <div className="d-flex">
                    {crew.profile && crew.profile.profile_photo_path ?
                        <div className='asset-item-image-wrapper'>
                            <img className='asset-item-image' src={crew.profile && crew.profile.profile_photo_path} alt={crew.profile.first_name.concat('profile Image')} />
                        </div> :
                        <div className='item-initial' style={{width: '50px', height: '50px'}}>
                            {crew.profile && crew.profile.first_name ? <span style={{fontSize: '25px'}}>{crew.profile.first_name[0]}</span>: <span style={{fontSize: '25px'}}>A</span>}
                        </div>}
                        <div className="d-flex flex-column" style={{justifyContent: 'center', maxWidth: '75%', textAlign: 'justify'}}>
                            {crew.profile && crew.profile.first_name ? <h5 className="mb-1">{crew.profile.first_name} {crew.profile.last_name}</h5> : <h5 className="mb-1">No name</h5>}
                            <span>{crew.email}</span>
                        </div>
                    </div>
                    <div style={{position: 'relative', display: 'flex', alignItems: 'center'}}>
                        <div className='crew-action-icon'>
                            <span className='bi bi-x fs-3 cancel-icon' title={`Remove ${crew.profile && crew.profile.first_name} From Crew.`} onClick={() => handleRemoveManager(index, crew.id)}></span>
                        </div>
                    </div>
            </div>
        </div>)}
    </div>
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'right'}}>    
        <button onClick={handleSubmit} disabled={loading ? true : false} id="kt_new_password_submit" className="btn btn-sm btn-beysix fwt-bolder">
            {!loading && <span className="indicator-label">Submit</span>}
            {loading && <span className="indicator-progress">Please wait...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>}
        </button>
    </div>
</div>
  )
}
