import React, {useState, useEffect} from 'react'
import AssetActivations from '../AssetActivations/AssetActivations'
import AssetMedia from '../AssetMedia/AssetMedia'
import AssetItems from '../AssetItems/AssetItems'
import EditAsset from '../EditAsset/EditAsset'
import { useParams } from 'react-router'
import api from '../../../store/api'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import parseDateTimestamp from '../../Utilities/parseDateTimeStamp.'
import convertCm from '../../Utilities/convertCm'
import ActionsDdl from '../../Utilities/ActionsDdl'
import FeedbackModal from '../../Utilities/FeedbackModal'
import AssertiveModal from '../../Utilities/AssertiveModal'

export default function DetailedAsset() {

  const [currentTab, setCurrentTab] = useState(1)
  const [assetData, setAssetData] = useState({
    "id": 0,
    "first_image": {
        "id": 0,
        "image_url": null,
        "image_path_name": null,
        "created_at": null,
        "asset": 0
    },
    "item_count": 0,
    "asset_zone": "",
    "asset_warehouse": {
        "warehouse_name": "",
        "warehouse_id": 0
    },
    "asset_brand": {
        "brand_name": "",
        "brand_id": 0
    },
    "asset_project": {
        "project_name": "",
        "project_id": 0
    },
    "asset_client": {
        "client_name": "",
        "client_id": 0
    },
    "brand_representative": {
        "brand_representative_name": "",
        "brand_representative_id": 0
    },
    "images": [],
    "asset_name": "",
    "asset_description": "",
    "created_at": "",
    "updated_at": "",
    "created_by_name": "",
    "updated_by_name": "",
    "active": false,
    "asset_length": 0.0,
    "asset_width": 0.0,
    "asset_height": 0.0,
    "warehouse_zone": 0,
    "created_by": 0,
    "updated_by": null,
    "asset_items": [],
    "asset_tools": []
})
  const token = useSelector((state) => state.loginSlice.token);
  const { id } = useParams() 
  const getAsset = () => {
    api.get(`/api/assets/${id}/`,
    {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
    .then((response) => {
      setAssetData(response.data)
    })
    .catch((error) => {
        throw new Error(error)
    })
  }

  const updateAssetStatus = (status) => {
    if (status === true){
      const data = {'set_active_status': !assetData.active}
      api.post(`/api/assets/update-asset-status/${id}/`, data,
        {headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
        })
        .then((response) => {
            if (response.status === 200) {
                  const data = response.data.active
                  if (data !== null || data !== undefined)
                    setAssetData({...assetData, active: data})
                  setRes({
                    message: 'You have successfully updated this asset`s status',
                    severity: "success",
                    show: true
                  })
                  setShowAssertiveModal({
                    show: false, 
                    promptText: '',
                    btnPrimary: {show: false, text: ''},
                    btnSecondary: {show: false, text: ''}
                  })
                } 
          })
        .catch((error) => {
          setLoading(false)
      })
    } else {
      setShowAssertiveModal({
        show: false, 
        promptText: '',
        btnPrimary: {show: false, text: ''},
        btnSecondary: {show: false, text: ''}
      })
    }
  } 

  const [res, setRes] = useState({
    message: '',
    severity: "success",
    show: false
  })

  const [showAssertiveModal, setShowAssertiveModal] = useState({
    show: false, 
    promptText: '',
    btnPrimary: {show: false, text: ''},
    btnSecondary: {show: false, text: ''}
  })

  const handleStatusAssertiveModal = (evt) => {
    evt.preventDefault()
    setShowAssertiveModal({
      show: true, 
      promptText: `Do you want to ${assetData.active ? 'Deactivate' : 'Activate'} this asset?`,
      btnPrimary: {show: true, text: `Yes, ${assetData.active ? 'Deactivate' : 'Activate'} it`},
      btnSecondary: {show: true, text: 'No, Take me back'}
    })
  }

  useEffect(() => {
    getAsset()
  }, [])
  return (
    <>
    <AssertiveModal 
					show = {showAssertiveModal.show}
					promptText = {showAssertiveModal.promptText} 
					btnPrimary = {{show: showAssertiveModal.btnPrimary.show, text: showAssertiveModal.btnPrimary.text}} 
					btnSecondary = {{show: showAssertiveModal.btnSecondary.show, text: showAssertiveModal.btnSecondary.text}} 
					handleActionBtnClick = {updateAssetStatus}
				/>
      <FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
        setRes({
          message: "",
          severity: "success",
          show: false
        })
      }}/>
    <div className='card mb-6 mb-xl-9'>
    <div className="card-body pt-9 pb-0">
          {/*begin::Details*/}
          <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
            {/*begin::Image*/}
            <div className="d-flex flex-center flex-shrink-0 bg-light rounded w-100px h-100px w-lg-150px h-lg-150px me-7 mb-4">
              {assetData.first_image ? assetData.first_image.image_url ? <img className="profile-image-objects" src={assetData.first_image.image_url} alt={assetData.asset_name} /> : <span className="svg-icon svg-icon-3x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path opacity="0.3" d="M22 19V17C22 16.4 21.6 16 21 16H8V3C8 2.4 7.6 2 7 2H5C4.4 2 4 2.4 4 3V19C4 19.6 4.4 20 5 20H21C21.6 20 22 19.6 22 19Z" fill="black"></path><path d="M20 5V21C20 21.6 19.6 22 19 22H17C16.4 22 16 21.6 16 21V8H8V4H19C19.6 4 20 4.4 20 5ZM3 8H4V4H3C2.4 4 2 4.4 2 5V7C2 7.6 2.4 8 3 8Z" fill="black"></path></svg></span>
              : <span className="svg-icon svg-icon-3x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path opacity="0.3" d="M22 19V17C22 16.4 21.6 16 21 16H8V3C8 2.4 7.6 2 7 2H5C4.4 2 4 2.4 4 3V19C4 19.6 4.4 20 5 20H21C21.6 20 22 19.6 22 19Z" fill="black"></path><path d="M20 5V21C20 21.6 19.6 22 19 22H17C16.4 22 16 21.6 16 21V8H8V4H19C19.6 4 20 4.4 20 5ZM3 8H4V4H3C2.4 4 2 4.4 2 5V7C2 7.6 2.4 8 3 8Z" fill="black"></path></svg></span>}
            </div>
            {/*end::Image*/}
            {/*begin::Wrapper*/}
            <div className="flex-grow-1">
              {/*begin::Head*/}
              <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                {/*begin::Details*/}
                <div className="d-flex flex-column" style={{minWidth: '100%'}}>
                  {/*begin::Status*/}
                  <div style={{display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <div  style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                      <div className="d-flex align-items-center mb-1">
                        <a className="text-gray-800 text-hover-primary fs-4 ftw-bold me-3">{assetData.asset_name}</a>
                        {assetData.active ? <span className="badge badge-light-success me-auto">Active</span> : <span className="badge badge-light-danger me-auto">Inactive</span>}
                      </div>
                      <ActionsDdl actions={[
                          {name: `${assetData.active ? 'Deactivate Asset' : 'Activate Asset'}`, returnFunction: handleStatusAssertiveModal},
                      ]} />
                      </div>
                    </div>
                  {/*end::Status*/}
                  {/*begin::Description*/}
                  <div className="d-flex flex-wrap ftw-bold mb-4 fs-6 text-gray-400">{assetData.asset_description}</div>
                  {/*end::Description*/}
                  <div className="d-flex flex-wrap ftw-bold fs-6 mb-4 pe-2">
                  {assetData.asset_brand && <Link className="d-flex align-items-center text-gray-400 text-hover-primary me-3 mb-2" to={`/brand-management/detailed-brand/${assetData.asset_brand.brand_id}`}>
                        <span className="svg-icon svg-icon-2x me-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M16.0077 19.2901L12.9293 17.5311C12.3487 17.1993 11.6407 17.1796 11.0426 17.4787L6.89443 19.5528C5.56462 20.2177 4 19.2507 4 17.7639V5C4 3.89543 4.89543 3 6 3H17C18.1046 3 19 3.89543 19 5V17.5536C19 19.0893 17.341 20.052 16.0077 19.2901Z"
                                    fill="black"
                                ></path>
                            </svg>
                        </span>
                        {assetData.asset_brand.brand_name}
                    </Link>}
                    {assetData.asset_client && <Link className="d-flex align-items-center ftw-bold text-gray-400 text-hover-primary me-3 mb-2" to={`/client-management/clients/detailed-client-organization/${assetData.asset_client.client_id}`}>
                        <span className="svg-icon svg-icon-2x me-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path
                                    opacity="0.3"
                                    d="M7.16973 20.95C6.26973 21.55 5.16972 20.75 5.46972 19.75L7.36973 14.05L2.46972 10.55C1.56972 9.95005 2.06973 8.55005 3.06973 8.55005H20.8697C21.9697 8.55005 22.3697 9.95005 21.4697 10.55L7.16973 20.95Z"
                                    fill="black"
                                ></path>
                                <path d="M11.0697 2.75L7.46973 13.95L16.9697 20.85C17.8697 21.45 18.9697 20.65 18.6697 19.65L13.1697 2.75C12.7697 1.75 11.3697 1.75 11.0697 2.75Z" fill="black"></path>
                            </svg>
                        </span>
                        {assetData.asset_client.client_name}
                    </Link>}
                    {assetData.asset_project && <Link className="d-flex align-items-center ftw-bold text-gray-400 text-hover-primary me-3 mb-2" to={`/project-management/detailed-project/${assetData.asset_project.project_id}`}>
                        <span className="svg-icon svg-icon-2x me-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path opacity="0.3" d="M8.9 21L7.19999 22.6999C6.79999 23.0999 6.2 23.0999 5.8 22.6999L4.1 21H8.9ZM4 16.0999L2.3 17.8C1.9 18.2 1.9 18.7999 2.3 19.1999L4 20.9V16.0999ZM19.3 9.1999L15.8 5.6999C15.4 5.2999 14.8 5.2999 14.4 5.6999L9 11.0999V21L19.3 10.6999C19.7 10.2999 19.7 9.5999 19.3 9.1999Z" fill="black"></path><path d="M21 15V20C21 20.6 20.6 21 20 21H11.8L18.8 14H20C20.6 14 21 14.4 21 15ZM10 21V4C10 3.4 9.6 3 9 3H4C3.4 3 3 3.4 3 4V21C3 21.6 3.4 22 4 22H9C9.6 22 10 21.6 10 21ZM7.5 18.5C7.5 19.1 7.1 19.5 6.5 19.5C5.9 19.5 5.5 19.1 5.5 18.5C5.5 17.9 5.9 17.5 6.5 17.5C7.1 17.5 7.5 17.9 7.5 18.5Z" fill="black"></path></svg>
                        </span>
                        {assetData.asset_project.project_name}
                    </Link>}
                    {assetData.asset_warehouse && <Link className="d-flex align-items-center ftw-bold text-gray-400 text-hover-primary me-3 mb-2" to={`/project-management/detailed-project/${assetData.asset_warehouse.warehouse_id}`}>
                        <span className="svg-icon svg-icon-2x me-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M11 2.375L2 9.575V20.575C2 21.175 2.4 21.575 3 21.575H9C9.6 21.575 10 21.175 10 20.575V14.575C10 13.975 10.4 13.575 11 13.575H13C13.6 13.575 14 13.975 14 14.575V20.575C14 21.175 14.4 21.575 15 21.575H21C21.6 21.575 22 21.175 22 20.575V9.575L13 2.375C12.4 1.875 11.6 1.875 11 2.375Z" fill="black"></path></svg>
                        </span>
                        {assetData.asset_warehouse.warehouse_name}
                    </Link>}
                    {assetData.asset_zone && <a className="d-flex align-items-center ftw-bold text-gray-400 me-3 mb-2">
                        <span className="svg-icon svg-icon-2x me-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path opacity="0.3" d="M18.0624 15.3454L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3454C4.56242 13.6454 3.76242 11.4452 4.06242 8.94525C4.56242 5.34525 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24525 19.9624 9.94525C20.0624 12.0452 19.2624 13.9454 18.0624 15.3454ZM13.0624 10.0453C13.0624 9.44534 12.6624 9.04534 12.0624 9.04534C11.4624 9.04534 11.0624 9.44534 11.0624 10.0453V13.0453H13.0624V10.0453Z" fill="black"></path><path d="M12.6624 5.54531C12.2624 5.24531 11.7624 5.24531 11.4624 5.54531L8.06241 8.04531V12.0453C8.06241 12.6453 8.46241 13.0453 9.06241 13.0453H11.0624V10.0453C11.0624 9.44531 11.4624 9.04531 12.0624 9.04531C12.6624 9.04531 13.0624 9.44531 13.0624 10.0453V13.0453H15.0624C15.6624 13.0453 16.0624 12.6453 16.0624 12.0453V8.04531L12.6624 5.54531Z" fill="black"></path></svg>
                        </span>
                        {assetData.asset_zone}
                    </a>}
                    {assetData.brand_representative && <Link className="d-flex align-items-center ftw-bold text-gray-400 text-hover-primary me-3 mb-2" to={`/user-management/users/user-profile/${assetData.brand_representative.brand_representative_id}`}>
                        <span className="svg-icon svg-icon-2x me-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z" fill="black"/>
                        <rect opacity="0.3" x="8" y="3" width="8" height="8" rx="4" fill="black"/>
                        </svg>
                        </span>
                        {assetData.brand_representative.brand_representative_name}
                    </Link>}
                </div>

                </div>
                {/*end::Details*/}
                {/*begin::Actions*/}
                {/* <div className="d-flex mb-4">
                  <a  className="btn btn-sm btn-bg-light btn-active-color-primary me-3" data-bs-toggle="modal" data-bs-target="#kt_modal_users_search">Add User</a>
                  <a  className="btn btn-sm btn-primary me-3" data-bs-toggle="modal" data-bs-target="#kt_modal_new_target">Add Target</a>
                  <div className="me-0">
                    <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                      <i className="bi bi-three-dots fs-3"></i>
                    </button>
                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3" data-kt-menu="true">
                      <div className="menu-item px-3">
                        <div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Payments</div>
                      </div>
                      <div className="menu-item px-3">
                        <a  className="menu-link px-3">Create Invoice</a>
                      </div>
                      <div className="menu-item px-3">
                        <a  className="menu-link flex-stack px-3">Create Payment
                        <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Specify a target name for future usage and reference" aria-label="Specify a target name for future usage and reference"></i></a>
                      </div>
                      <div className="menu-item px-3">
                        <a  className="menu-link px-3">Generate Bill</a>
                      </div>
                </div> */}
              </div>
              {/*end::Head*/}
              {/*begin::Info*/}
              <div className="d-flex flex-wrap justify-content-start">
                {/*begin::Stats*/}
                <div className="d-flex flex-wrap">
                  {/*begin::Stat*/}
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-2" style={{maxHeight: '70px'}}>
                      <div className="fwt-bold fs-6 text-gray-400">
                          <span className="svg-icon svg-icon-3 me-2">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path opacity="0.3" d="M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z" fill="black"></path>
                                  <path
                                      d="M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z"
                                      fill="black"
                                  ></path>
                                  <path
                                      d="M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z"
                                      fill="black"
                                  ></path>
                              </svg>
                          </span>
                          <span className="fw-bold fs-6 text-gray-400">Created Date</span>
                      </div>
                      <div className="d-flex align-items-center"><div className="fs-6 ftw-bold">{parseDateTimestamp(assetData.created_at)}</div></div>
                  </div>
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-2" style={{maxHeight: '70px'}}>
                    <div className="fwt-bold fs-6 text-gray-400">
                        <span className="svg-icon svg-icon-3 me-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path opacity="0.3" d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z" fill="black"></path>
                                <path d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z" fill="black"></path>
                            </svg>
                        </span>
                        <span className="fw-bold fs-6 text-gray-400">Created By</span>
                    </div>
                    <div className="d-flex align-items-center"><Link className="fs-6 fwt-bold counted text-hover-primary text-dark" to={`/user-management/users/user-profile/${assetData.created_by}`}>{assetData.created_by_name}</Link></div>
                </div>
                {assetData.updated_by &&
                <>
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-2" style={{maxHeight: '70px'}}>
                      <div className="fwt-bold fs-6 text-gray-400">
                          <span className="svg-icon svg-icon-3 me-2">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path opacity="0.3" d="M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z" fill="black"></path>
                                  <path
                                      d="M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z"
                                      fill="black"
                                  ></path>
                                  <path
                                      d="M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z"
                                      fill="black"
                                  ></path>
                              </svg>
                          </span>
                          <span className="fw-bold fs-6 text-gray-400">Update Date</span>
                      </div>
                      <div className="d-flex align-items-center"><div className="fs-6 ftw-bold">{parseDateTimestamp(assetData.updated_at)}</div></div>
                  </div>
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-2" style={{maxHeight: '70px'}}>
                    <div className="fwt-bold fs-6 text-gray-400">
                        <span className="svg-icon svg-icon-3 me-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path opacity="0.3" d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z" fill="black"></path>
                                <path d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z" fill="black"></path>
                            </svg>
                        </span>
                        <span className="fw-bold fs-6 text-gray-400">Updated By</span>
                    </div>
                    <div className="d-flex align-items-center"><Link className="fs-6 fwt-bold counted text-hover-primary text-dark" to={`/user-management/users/user-profile/${assetData.updated_by}`}>{assetData.updated_by_name}</Link></div>
                </div>
                </>
                }
                {(assetData.asset_length !== '' && assetData.asset_length && assetData.asset_length !== 0.0) && <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-2" style={{maxHeight: '70px'}}>
                    <div className="fwt-bold fs-6 text-gray-400">
                        <span className="svg-icon svg-icon-3 me-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M6.2679 4.8546L4.8537 6.2688L17.7936 19.2087L19.2078 17.7945L6.2679 4.8546Z" fill="black"/>
                        <path opacity="0.3" d="M9.1 2H3C2.4 2 2 2.4 2 3V9.09998L9.1 2ZM22 14.9V21C22 21.6 21.6 22 21 22H14.9L22 14.9Z" fill="black"/>
                        </svg>
                        </span>
                        <span className="fw-bold fs-6 text-gray-400">Asset Length</span>
                    </div>
                    <div className="d-flex align-items-center"><a className="fs-6 fwt-bold counted text-hover-primary text-dark" >{convertCm(assetData.asset_length)}</a></div>
                </div>}
                {(assetData.asset_width !== '' && assetData.asset_width && assetData.asset_width !== 0.0) && <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-2" style={{maxHeight: '70px'}}>
                    <div className="fwt-bold fs-6 text-gray-400">
                        <span className="svg-icon svg-icon-3 me-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M17.4 11H6.60001V13H17.4V11Z" fill="black"/>
                        <path opacity="0.3" d="M17.4 7L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L17.4 17V7ZM6.60002 7L2.3 11.3C1.9 11.7 1.9 12.3 2.3 12.7L6.60002 17V7Z" fill="black"/>
                        </svg>
                        </span>
                        <span className="fw-bold fs-6 text-gray-400">Asset Width</span>
                    </div>
                    <div className="d-flex align-items-center"><a className="fs-6 fwt-bold counted text-hover-primary text-dark" >{convertCm(assetData.asset_width)}</a></div>
                </div>}
                {(assetData.asset_height !== '' && assetData.asset_height && assetData.asset_height !== 0.0) && <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-2" style={{maxHeight: '70px'}}>
                    <div className="fwt-bold fs-6 text-gray-400">
                        <span className="svg-icon svg-icon-3 me-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M13 6.59998H11V17.4H13V6.59998Z" fill="black"/>
                        <path opacity="0.3" d="M7 6.59999L11.3 2.3C11.7 1.9 12.3 1.9 12.7 2.3L17 6.59999H7ZM7 17.4L11.3 21.7C11.7 22.1 12.3 22.1 12.7 21.7L17 17.4H7Z" fill="black"/>
                        </svg>
                        </span>
                        <span className="fw-bold fs-6 text-gray-400">Asset Height</span>
                    </div>
                    <div className="d-flex align-items-center"><a className="fs-6 fwt-bold counted text-hover-primary text-dark" >{convertCm(assetData.asset_height)}</a></div>
                </div>}
                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-2" style={{maxHeight: '70px'}}>
                    <div className="fwt-bold fs-6 text-gray-400">
                        <span className="svg-icon svg-icon-3 me-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path opacity="0.3" d="M5 8.04999L11.8 11.95V19.85L5 15.85V8.04999Z" fill="black"></path><path d="M20.1 6.65L12.3 2.15C12 1.95 11.6 1.95 11.3 2.15L3.5 6.65C3.2 6.85 3 7.15 3 7.45V16.45C3 16.75 3.2 17.15 3.5 17.25L11.3 21.75C11.5 21.85 11.6 21.85 11.8 21.85C12 21.85 12.1 21.85 12.3 21.75L20.1 17.25C20.4 17.05 20.6 16.75 20.6 16.45V7.45C20.6 7.15 20.4 6.75 20.1 6.65ZM5 15.85V7.95L11.8 4.05L18.6 7.95L11.8 11.95V19.85L5 15.85Z" fill="black"></path></svg>
                        </span>
                        <span className="fw-bold fs-6 text-gray-400">Item Count</span>
                    </div>
                    <div className="d-flex align-items-center"><a className="fs-6 fwt-bold counted text-hover-primary text-dark" >{assetData.item_count} {assetData.item_count === 1 ? 'Item' : 'Items'}</a></div>
                  </div>
                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-2" style={{maxHeight: '70px'}}>
                    <div className="fwt-bold fs-6 text-gray-400">
                        <span className="svg-icon svg-icon-3 me-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path opacity="0.3" d="M22.0318 8.59998C22.0318 10.4 21.4318 12.2 20.0318 13.5C18.4318 15.1 16.3318 15.7 14.2318 15.4C13.3318 15.3 12.3318 15.6 11.7318 16.3L6.93177 21.1C5.73177 22.3 3.83179 22.2 2.73179 21C1.63179 19.8 1.83177 18 2.93177 16.9L7.53178 12.3C8.23178 11.6 8.53177 10.7 8.43177 9.80005C8.13177 7.80005 8.73176 5.6 10.3318 4C11.7318 2.6 13.5318 2 15.2318 2C16.1318 2 16.6318 3.20005 15.9318 3.80005L13.0318 6.70007C12.5318 7.20007 12.4318 7.9 12.7318 8.5C13.3318 9.7 14.2318 10.6001 15.4318 11.2001C16.0318 11.5001 16.7318 11.3 17.2318 10.9L20.1318 8C20.8318 7.2 22.0318 7.59998 22.0318 8.59998Z" fill="black"></path><path d="M4.23179 19.7C3.83179 19.3 3.83179 18.7 4.23179 18.3L9.73179 12.8C10.1318 12.4 10.7318 12.4 11.1318 12.8C11.5318 13.2 11.5318 13.8 11.1318 14.2L5.63179 19.7C5.23179 20.1 4.53179 20.1 4.23179 19.7Z" fill="black"></path></svg>
                        </span>
                        <span className="fw-bold fs-6 text-gray-400">No. Tools Used</span>
                    </div>
                    <div className="d-flex align-items-center"><a className="fs-6 fwt-bold counted text-hover-primary text-dark" >{assetData.tool_count} {assetData.tool_count === 1 ? 'Tool' : 'Tools'}</a></div>
                  </div>
                </div>
                {/*end::Stats*/}
              </div>
              {/*end::Info*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Details*/}
          <div className="separator"></div>
          {/*begin::Nav wrapper*/}
          <div className="d-flex overflow-auto h-55px">
            {/*begin::Nav links*/}
            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-6 ftw-bold flex-nowrap">
              {/*begin::Nav item*/}
              <li className="nav-item">
                <a onClick={() => setCurrentTab(1)} className={currentTab === 1 ? "nav-link text-active-primary me-6 active" : "nav-link text-active-primary me-6"}>Media</a>
              </li>
              {/*end::Nav item*/}
              {/*begin::Nav item*/}
              <li className="nav-item">
                <a onClick={() => setCurrentTab(2)} className={currentTab === 2 ? "nav-link text-active-primary me-6 active" : "nav-link text-active-primary me-6"}>Items</a>
              </li>
              {/*end::Nav item*/}
              {/*begin::Nav item*/}
              <li className="nav-item">
                <a onClick={() => setCurrentTab(3)} className={currentTab === 3 ? "nav-link text-active-primary me-6 active" : "nav-link text-active-primary me-6"}>Activations</a>
              </li>
              {/*end::Nav item*/}
              {/*begin::Nav item*/}
              <li className="nav-item">
                <a onClick={() => setCurrentTab(4)} className={currentTab === 4 ? "nav-link text-active-primary me-6 active" : "nav-link text-active-primary me-6"}>Edit Asset</a>
              </li>
              {/*end::Nav item*/}
            </ul>
            {/*end::Nav links*/}
          </div>
          {/*end::Nav wrapper*/}
        </div>
    </div>
    {currentTab === 1 && <AssetMedia images={assetData.images} />}
    {currentTab === 2 && <AssetItems />}
    {currentTab === 3 && <AssetActivations />}
    {currentTab === 4 && <EditAsset data = {assetData} setData={(data) => setAssetData(data)}/>}
    </>
  )
}
