import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import api from '../../../../store/api';
import MainBarChart from '../../../Charts/BarChart/BarChart';
import DoughnutChart from '../../../Charts/DoughnutChart/DoughnutChart';

export default function AssetDashboard() {

  const [pageLoading, setPageLoading] = useState(false)
  const [assetCounts, setAssetCounts] = useState({})
  const [clientProjects, setClientProjects] = useState([])
  const [clientBrands, setClientBrands] = useState([])
  const [top10Projects_spaceOccupied, setTop10Projects_spaceOccupied] = useState([])
  const [top10Projects_assetCount, setTop10Projects_assetCount] = useState([])
  const [assetDataPerAgency, setAssetDataPerAgency] = useState([])
  const [projectPerAgency, setProjectPerAgency] = useState([])
  const [brandPerAgency, setBrandPerAgency] = useState([])
  const [projectItemCount, setProjectItemCount] = useState([])


  const getAssetCountDetails = () => {
    api.get(`/api/dashboards/get-asset-count/${organization.id}/`,
      {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
      .then((response) => {
          if (response.status === 200) {
            setAssetCounts(response.data.count)
            setClientProjects(response.data.project_table)
            setClientBrands(response.data.brands_data)
            setTop10Projects_spaceOccupied(response.data.top_projects_data_by_space_occupied)
            setTop10Projects_assetCount(response.data.top_projects_data)
            setAssetDataPerAgency(response.data.assets_per_commissioning_agency)
            setProjectPerAgency(response.data.project_per_agency)
            setBrandPerAgency(response.data.brands_per_commissioning_agency_count)
            setProjectItemCount(response.data.top_projects_data_per_item)
            setPageLoading(false)
          }
      })
      .catch((error) => {
          setPageLoading(false)
          throw new Error('Error getting data.')
      })
  }

  const {organization, token} = useSelector((state) => {
    return {
      'organization': state.loginSlice.organization, 
      'token': state.loginSlice.token
    }
  }); //

  useEffect(() => {
    getAssetCountDetails()
  }, [])

  return (
        <div className="row g-6 g-xl-9">
        <div className="col-lg-6 col-xxl-3">
            {/*begin::Card*/}
            <div className="card h-100">
                {/*begin::Card body*/}
                <div className="card-body p-9">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <div className="fs-2hx fw-bolder">{assetCounts.total_assets__count}</div>
                      <span className="svg-icon svg-icon-muted svg-icon-3hx" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path opacity="0.3" d="M22 19V17C22 16.4 21.6 16 21 16H8V3C8 2.4 7.6 2 7 2H5C4.4 2 4 2.4 4 3V19C4 19.6 4.4 20 5 20H21C21.6 20 22 19.6 22 19Z" fill="black"/>
                      <path d="M20 5V21C20 21.6 19.6 22 19 22H17C16.4 22 16 21.6 16 21V8H8V4H19C19.6 4 20 4.4 20 5ZM3 8H4V4H3C2.4 4 2 4.4 2 5V7C2 7.6 2.4 8 3 8Z" fill="black"/>
                      </svg></span>
                    </div>
                    <div className="fs-5 fwt-bold text-gray-400 mb-7">Total {assetCounts.total_assets__count === 1 ? 'Asset' : 'Assets'}</div>
                </div>
                {/*end::Card body*/}
            </div>
            {/*end::Card*/}
        </div>
        <div className="col-lg-6 col-xxl-3">
            {/*begin::Budget*/}
            <div className="card h-100">
                {/*begin::Card body*/}
                <div className="card-body p-9">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="fs-2hx fw-bolder">{assetCounts.active_assets_count}</div>
                      <span className="svg-icon svg-icon-3hx svg-icon-success" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path opacity="0.3" d="M22 19V17C22 16.4 21.6 16 21 16H8V3C8 2.4 7.6 2 7 2H5C4.4 2 4 2.4 4 3V19C4 19.6 4.4 20 5 20H21C21.6 20 22 19.6 22 19Z" fill="black"/>
                      <path d="M20 5V21C20 21.6 19.6 22 19 22H17C16.4 22 16 21.6 16 21V8H8V4H19C19.6 4 20 4.4 20 5ZM3 8H4V4H3C2.4 4 2 4.4 2 5V7C2 7.6 2.4 8 3 8Z" fill="black"/>
                      </svg></span>
                    </div>
                    <div className="fs-5 fwt-bold text-gray-400 mb-7">Active {assetCounts.active_assets_count === 1 ? 'Asset' : 'Assets'}</div>
                </div>
            {/*end::Budget*/}
        </div>
        </div>
        <div className="col-lg-6 col-xxl-3">
            {/*begin::Clients*/}
            <div className="card h-100">
                <div className="card-body p-9">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="fs-2hx fw-bolder">{assetCounts.inactive_assets_count}</div>
                      <span className="svg-icon svg-icon-3hx svg-icon-danger" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path opacity="0.3" d="M22 19V17C22 16.4 21.6 16 21 16H8V3C8 2.4 7.6 2 7 2H5C4.4 2 4 2.4 4 3V19C4 19.6 4.4 20 5 20H21C21.6 20 22 19.6 22 19Z" fill="black"/>
                      <path d="M20 5V21C20 21.6 19.6 22 19 22H17C16.4 22 16 21.6 16 21V8H8V4H19C19.6 4 20 4.4 20 5ZM3 8H4V4H3C2.4 4 2 4.4 2 5V7C2 7.6 2.4 8 3 8Z" fill="black"/>
                      </svg></span>
                    </div>
                    <div className="fs-5 fwt-bold text-gray-400 mb-7">Inactive {assetCounts.inactive_assets_count === 1 ? 'Asset' : 'Assets'}</div>
                </div>
            </div>
            {/*end::Clients*/}
        </div>
        <div className="col-lg-6 col-xxl-3">
            {/*begin::Clients*/}
            <div className="card h-100">
                <div className="card-body p-9">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="fs-2hx fw-bolder">{assetCounts.total_space_occupied} ft²</div>
                     <span className="svg-icon svg-icon-muted svg-icon-3hx icon icon-success" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M8.7 4.19995L4 6.30005V18.8999L8.7 16.8V19L3.1 21.5C2.6 21.7 2 21.4 2 20.8V6C2 5.4 2.3 4.89995 2.9 4.69995L8.7 2.09998V4.19995Z" fill="black"/>
                      <path d="M15.3 19.8L20 17.6999V5.09992L15.3 7.19989V4.99994L20.9 2.49994C21.4 2.29994 22 2.59989 22 3.19989V17.9999C22 18.5999 21.7 19.1 21.1 19.3L15.3 21.8998V19.8Z" fill="black"/>
                      <path opacity="0.3" d="M15.3 7.19995L20 5.09998V17.7L15.3 19.8V7.19995Z" fill="black"/>
                      <path opacity="0.3" d="M8.70001 4.19995V2L15.4 5V7.19995L8.70001 4.19995ZM8.70001 16.8V19L15.4 22V19.8L8.70001 16.8Z" fill="black"/>
                      <path opacity="0.3" d="M8.7 16.8L4 18.8999V6.30005L8.7 4.19995V16.8Z" fill="black"/>
                      </svg></span>
                    </div>
                    <div className="fs-5 fwt-bold text-gray-400 mb-7">Total Space Occupied</div>
                </div>
            </div>
            {/*end::Clients*/}
        </div>
        <div className="col-xl-4 mt-3">
										{/*begin::List Widget 4*/}
										<div className="card card-xl-stretch mb-xl-8">
											{/*begin::Header*/}
											<div className="card-header border-0 pt-2">
												<h3 className="card-title align-items-start flex-column">
													<span className="card-label fwt-bolder text-dark">Projects</span>
													<span className="text-muted mt-1 fwt-bold fs-7">Total of {clientProjects.length} projects</span>
												</h3>
											</div>
											{/*end::Header*/}
											{/*begin::Body*/}
											<div className="card-body pt-5" style={{maxHeight: '370px', overflow: 'auto'}}>
												{/*begin::Item*/}
												{clientProjects.map((project) =><div className="d-flex align-items-sm-center mb-7">
													{/*begin::Symbol*/}
													<div className="symbol symbol-50px me-5">
														<span className="symbol-label">
															<img src={project.image_path} className="align-self-center" alt={project.name} 
                              style={{objectFit: 'cover', maxWidth: '100%', minWidth: '100%'}}/>
														</span>
													</div>
													{/*end::Symbol*/}
													{/*begin::Section*/}
													<div className="d-flex align-items-center flex-row-fluid flex-wrap">
														<div className="flex-grow-1 me-2">
															<a href="#" className="text-gray-800 text-hover-primary fs-6 fw-bolder">{project.name}</a>
															<span className="text-muted fw-bold d-block fs-7">{`${project.assets_count} ${project.assets_count === 1 ? 'Asset' : 'Assets'}`}</span>
														</div>
														<span className="badge badge-light fw-bolder my-2">{project.brand_name}</span>
													</div>
													{/*end::Section*/}
												</div>)}
											</div>
											{/*end::Body*/}
										</div>
										{/*end::List Widget 4*/}
									</div>
                  <div className="col-lg-6 col-xxl-4 mt-3">
                    <div className="card card-xl-stretch mb-xl-8">
                        {/*begin::Header*/}
                        <div className="card-header border-0 pt-2">
                          <h5 className="card-title align-items-start flex-column mb-2">
                            <span className="fwt-bolder text-dark">Projects Per Commissioning Agency</span>
                          </h5>
                      <MainBarChart _data = {projectPerAgency} />
                    </div>
                    </div>
                    </div>
                    <div className="col-lg-4 col-xxl-4 mt-3">
                    <div className="card card-xl-stretch mb-xl-8">
                        {/*begin::Header*/}
                        <div className="card-header border-0 pt-2">
                          <h5 className="card-title align-items-start flex-column mb-2">
                            <span className="fwt-bolder text-dark">Top 10 Projects In Regards To Number Of Assets</span>
                          </h5>
                      <DoughnutChart _data = {top10Projects_assetCount} />
                    </div>
                    </div>
                    </div>
                    <div className="col-lg-4 col-xxl-4 mt-3">
                    <div className="card card-xl-stretch mb-xl-8">
                        {/*begin::Header*/}
                        <div className="card-header border-0 pt-2">
                          <h5 className="card-title align-items-start flex-column mb-2">
                            <span className="fwt-bolder text-dark">Top 10 Projects In Regards To Number Of Items</span>
                          </h5>
                      <DoughnutChart _data = {projectItemCount} />
                    </div>
                    </div>          
                    </div>          
                    <div className="col-lg-4 col-xxl-4 mt-3">
                    <div className="card card-xl-stretch mb-xl-8">
                        {/*begin::Header*/}
                        <div className="card-header border-0 pt-2">
                          <h5 className="card-title align-items-start flex-column mb-2">
                            <span className="fwt-bolder text-dark">Top 10 Projects In Regards To Space Occupied</span>
                          </h5>
                      <DoughnutChart _data = {top10Projects_spaceOccupied} />
                    </div>
                    </div>          
                    </div>   
                    <div className="col-xl-4 mt-3">
										{/*begin::List Widget 4*/}
										<div className="card card-xl-stretch mb-xl-8">
											{/*begin::Header*/}
											<div className="card-header border-0 pt-2">
												<h3 className="card-title align-items-start flex-column">
													<span className="card-label fwt-bolder text-dark">Brands</span>
													<span className="text-muted mt-1 fwt-bold fs-7">Total of {clientBrands.length} brands</span>
												</h3>
											</div>
											{/*end::Header*/}
											{/*begin::Body*/}
											<div className="card-body pt-5" style={{maxHeight: '370px', overflow: 'auto'}}>
												{/*begin::Item*/}
												{clientBrands.map((brand) =><div className="d-flex align-items-sm-center mb-7">
													{/*begin::Symbol*/}
													<div className="symbol symbol-50px me-5">
														<span className="symbol-label">
															<img src={brand.image_path} className="align-self-center" alt={brand.name} 
                              style={{objectFit: 'cover', maxWidth: '100%', minWidth: '100%'}}/>
														</span>
													</div>
													{/*end::Symbol*/}
													{/*begin::Section*/}
													<div className="d-flex align-items-center flex-row-fluid flex-wrap">
														<div className="flex-grow-1 me-2">
															<a href="#" className="text-gray-800 text-hover-primary fs-6 fw-bolder">{brand.name}</a>
															<span className="text-muted fw-bold d-block fs-7">{`${brand.assets_count} ${brand.assets_count === 1 ? 'Asset' : 'Assets'}`}</span>
														</div>
													</div>
													{/*end::Section*/}
												</div>)}
											</div>
											{/*end::Body*/}
										</div>
										{/*end::List Widget 4*/}
									</div>
                      <div className="col-lg-6 col-xxl-5 mt-3">
                    <div className="card card-xl-stretch mb-xl-8">
                        {/*begin::Header*/}
                        <div className="card-header border-0 pt-2">
                          <h5 className="card-title align-items-start flex-column mb-2">
                            <span className="fwt-bolder text-dark">Brands Per Commissioning Agency</span>
                          </h5>
                        <MainBarChart _data = {brandPerAgency} />
                    </div>
                    </div>
                    </div>
                      <div className="col-lg-6 col-xxl-5 mt-3">
                    <div className="card card-xl-stretch mb-xl-8">
                        {/*begin::Header*/}
                        <div className="card-header border-0 pt-2">
                          <h5 className="card-title align-items-start flex-column mb-2">
                            <span className="fwt-bolder text-dark">Assets Per Commissioning Agency</span>
                          </h5>
                        <MainBarChart _data = {assetDataPerAgency} />
                    </div>
                    </div>
                    </div>
									</div>    

  )
}
