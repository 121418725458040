import React, {useEffect, useState} from 'react'
import api from '../../../store/api'
import { useSelector } from 'react-redux';
import ACtivationsTable from '../ActivationsTable/ActivationsTable';
import { Link } from 'react-router-dom';
import NoResults from '../../Utilities/NoResults';
import { motion } from 'framer-motion'

export default function ActivationsList() {
	
  const [activationsList, setActivationsList] = useState([])
  const [resultsFound, setResultsFound] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [pageLoading, setPageLoading] = useState(false)
  const [ogActivations, setOgActivations] = useState([])
  
  const [subsequentLoading, setSubsequentLoading] = useState(false)
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const {organization, token, user} = useSelector((state) => {
    return {
      'organization': state.loginSlice.organization, 
      'token': state.loginSlice.token,
      'user': state.loginSlice.user
    }
  }); //
  
  const getActivations = () => {
    if (page === 1)
      setPageLoading(true)
    else
      setSubsequentLoading(true)

    if (page >= 1 && page <= totalPages) { 
      let url = `/api/activations/activation/?page=${page}`
      if (user.user_type === 'client') {
        url = `/api/activations/organization-activations/${organization.id}/?page=${page}`
      } 

      api.get(url,
        {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
        .then((response) => {
          if (response.status === 200) {
              if (response.data.results.length > 0){
                  if (page > 1){
                    setActivationsList([...activationsList, ...response.data.results])
                    setSubsequentLoading(false)
                  }
                  else {
                    setActivationsList(response.data.results)
                    setOgActivations(response.data.results)
                    setCount(response.data.count)
                    setResultsFound(true)
                    setTotalPages(response.data.total_pages)
                  }
              } else {
                  setActivationsList(response.data.results)
                  setResultsFound(false)
              }
          setPageLoading(false)
          }
        })
          .catch((error) => {
              throw new Error(error) 
          })
      }
  }

  const handleSearch = (evt) => {
    evt.preventDefault()
    const value = evt.target.value
    setSearchQuery(value)
    if (value.length > 2) {
        setPageLoading(true)
        let url = `/api/activations/search-all-activations/`
        if (user.user_type === 'client') {
          url = `/api/activations/search-all-activations/${organization.id}/`
        } 
        api.post(url, {searchQuery: value},
        {headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
        })
        .then((response) => {
            if (response.status === 200) {
                if (response.data.results.length > 0){
                    setActivationsList(response.data.results)
                    setResultsFound(true)
                } else {
                    setResultsFound(false)
                }
                setPageLoading(false)
            }
        })
        .catch((error) => {
            if (response.status === 404) {

            } else {
                throw new Error(error)
            }
        })

    } else {
        setActivationsList(ogActivations)
        if (ogActivations.length > 0)
            setResultsFound(true)
    }
  }

  useEffect(() => {
    getActivations()
  }, [page])
    const permissions = useSelector((state) => state.loginSlice.permissions);

  return (
    <>
    {!pageLoading && <motion.p 
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.3 }} style={{fontWeight: '600'}}>{count} Activations Total</motion.p>}
    <div className="card mb-5 mb-xl-8">
    {/*begin::Header*/}
    <div className="card-header border-0 pt-5 align-items-center">
        <h3 className="card-title align-items-start flex-column">
          <div className="d-flex align-items-center position-relative my-1">
              <span className="svg-icon svg-icon-1 position-absolute ms-6">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect><path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path></svg>
              </span>
              <input type="text" className="form-control form-control-solid w-250px ps-14" onChange={handleSearch} value={searchQuery} placeholder="Search Activation" />
            </div>
        </h3>
        {permissions['add_activation'] && <div className="card-materialbar">
            <Link className="btn btn-sm btn-light-primary ftw-bold" to={'/activation-management/create-activation'} >
            <span className="svg-icon svg-icon-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="black"></rect>
                        <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="black"></rect>
                    </svg>
                </span>
                Create Activation
            </Link>
            {/*begin::Menu 2*/}
            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px" data-kt-menu="true">
            </div></div>}
    </div>
    {/*end::Header*/}
    {/*begin::Body*/}
    <div className="card-body py-3"></div>
      <ACtivationsTable activationsList={activationsList} pageLoading={pageLoading} resultsFound={resultsFound}  setPage={setPage} page={page} subsequentLoading={subsequentLoading}/>
    </div>
    {resultsFound === false && <NoResults />}
    </>
  )
}
